.download_page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
}

.download_page button {
    font-size: 20px;
    color: white;
    background-color: #e64a19;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 1% 4%;
}

.child_app_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.child_app_container .child_app_icon_area {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5% 0%;
}

.child_app_container .child_app_icon_area img {
    width: 20%;
}

.child_app_container .child_app_steps_container {
    width: 92%;
    padding: 4%;
}

.child_app_container .child_app_steps_container .child_app_single_step {
    margin-bottom: 30px;
}

.child_app_container .child_app_steps_container .child_app_single_step h2 {
    font-size: 20px;
    color: #262626;
}

.child_app_container .child_app_steps_container .child_app_single_step p {
    font-size: 16px;
    color: #5F6368;
}

.child_app_container .child_app_steps_container .child_app_single_step button {
    background-color: #007AFF;
    font-size: 16px;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 3% 5%;
    border-radius: 100px;
    margin-top: 10px;
    cursor: pointer;
}

.child_app_popup_outer {
    width: 100%;
    height: 100%;
    background-color: #00000047;
    z-index: 100;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

.child_app_popup_outer .child_app_popup_inner {
    width: 80%;
    height: 300px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 5%;
}

.child_app_popup_outer .child_app_popup_inner img {
    width: 18%;
    margin-bottom: 3%;
}

.child_app_popup_outer .child_app_popup_inner p {
    text-align: justify;
    margin: 0% 3%;
}

.child_app_popup_outer .child_app_popup_inner button {
    background-color: #007AFF;
    font-size: 16px;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 3% 5%;
    width: 40%;
    border-radius: 100px;
    margin-top: 10px;
    cursor: pointer;
}

.child_app_popup_outer .child_app_popup_inner button:disabled {
    background-color: #85c0ff;
    cursor: no-drop;
}