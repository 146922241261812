#full_page_loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#full_page_loader img {
    width: 100px;
    height: 100px;
}

#hero_area {
    background: url('./wallpaper.png') no-repeat;
    background-size: cover;
    width: 70%;
    padding: 4% 15% 4% 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#hero_area .left_containers {
    color: #013945;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 8px 15px 0px rgba(14, 74, 85, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2%;
    z-index: 10;
}

#hero_area .left_containers h2 {
    align-items: center;
    margin-bottom: 4%;
}

#hero_area .left_containers .hero_list_item {
    text-align: left;
}

#hero_area .left_containers .hero_list_item span {
    color: #e64a19;
    font-weight: 900;
    font-size: 20px;
}

#hero_area .pricing_containers {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#hero_area .pricing_containers .pricing_card {
    width: 220px;
}

#hero_area .pricing_containers .pricing_card:hover .pricing_card_main {
    border: 3px solid #e64a19;
    cursor: pointer;
}

#hero_area .pricing_containers .lifetime {
    margin-right: 20px;
}

#hero_area .pricing_containers .pricing_card .pricing_card_tagline {
    display: flex;
    justify-content: center;
}

#hero_area .pricing_containers .pricing_card .pricing_card_tagline .pricing_card_tag {
    background-color: #ffffff;
    border-radius: 5px 5px 0px 0px;
    font-size: 10px;
    padding: 1% 2%;
    color: #013945;
    font-weight: 700;
    width: 70px;
}

#hero_area .pricing_containers .lifetime .pricing_card_tagline .pricing_card_tag {
    background-color: #FFE1CE;
    color: #e64a19;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main {
    background-color: #ffffff;
    border-radius: 15px;
    border: 3px solid #ffffff;
    padding: 5% 4%;
}

#hero_area .pricing_containers .lifetime .pricing_card_main {
    background: transparent linear-gradient(139deg, #FFFAF5 0%, #FFE4D4 100%) 0% 0% no-repeat padding-box;
    border: 3px solid #FFE1CE;
}

#hero_area .pricing_containers .pricing_card:hover .pricing_card_tagline .pricing_card_tag {
    background-color: #e64a19;
    color: #ffffff;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_head h2 {
    color: #013945;
    font-size: 22px;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_head span {
    background-color: #B4FFBB;
    color: #009A0E;
    font-size: 10px;
    padding: 1% 3%;
    margin-bottom: 8px;
    border: 1px solid #84F890;
}

#hero_area .pricing_containers .lifetime .pricing_card_main .pricing_card_main_head span {
    color: #ffffff;
    background-color: #FF7E3B;
    border: 1px solid #FF5700;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body h1 {
    font-size: 15px;
    color: #698C94;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body h1 strong {
    font-size: 30px;
    color: #013945;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body span {
    font-size: 13px;
    color: #698C94;
    padding: 0% 10%;
    margin-bottom: 10px;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body span strike {
    color: #e64a19;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body button {
    background-color: #ffffff;
    border: 1px solid #e64a19;
    color: #e64a19;
    font-size: 15px;
    font-weight: 700;
    border-radius: 25px;
    padding: 5% 1%;
    margin-bottom: 5px;
    cursor: pointer;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body button:hover {
    background-color: #e64a19;
    color: #ffffff;
}

#hero_area .pricing_containers .lifetime .pricing_card_main .pricing_card_main_body button {
    color: #ffffff;
    background-color: #e64a19;
}

#hero_area .pricing_containers .lifetime .pricing_card_main .pricing_card_main_body button:hover {
    background-color: #ffffff;
    color: #e64a19;
}

#hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body p {
    font-size: 10px;
    color: #698C94
}

#home_app_stats_area {
    padding: 3% 15% 0% 15%;
    width: 70%;
}

#home_app_stats_area hr {
    margin: 6% 0%;
    border: 0.5px solid #C7DBE5;
}

#home_app_stats_area h1 {
    color: #013945;
    margin-bottom: 20px;
}

#home_app_stats_area .home_app_stats_boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4%;
}

#home_app_stats_area .home_app_stats_boxes .home_app_stats_container {
    width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#home_app_stats_area .home_app_stats_boxes .home_app_stats_container .home_stats_icon_area {
    width: 20%;
    text-align: center;
}

#home_app_stats_area .home_app_stats_boxes .home_app_stats_container .home_stats_icon_area .home_stats_icon_img {
    font-size: 40px;
    color: #e1306c;
}

#home_app_stats_area .home_app_stats_boxes .home_app_stats_container .home_stats_content_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#home_app_stats_area .home_app_stats_boxes .home_app_stats_container .home_stats_content_area span {
    text-align: left;
}

#home_testimonial_area {
    padding: 3% 15% 0% 15%;
    width: 70%;
}

#home_testimonial_area hr {
    margin: 6% 0%;
    border: 0.5px solid #C7DBE5;
}

#home_testimonial_area h1 {
    color: #013945;
    margin-bottom: 20px;
}

#home_testimonial_area .home_testimonial_boxes {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#home_testimonial_area .home_testimonial_boxes .testimonial_single_container {
    width: 29%;
    font-size: 12px;
    border: 2px solid #EFEFEF;
    border-radius: 20px;
    padding: 1%;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#home_testimonial_area .home_testimonial_boxes .testimonial_single_container .testimonial_author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#home_testimonial_area .home_testimonial_boxes .testimonial_single_container .testimonial_author .author_name {
    font-size: 15px;
    margin-right: 5px;
}

#home_testimonial_area .home_testimonial_boxes .testimonial_single_container .testimonial_author .author_rating {
    width: 25%;
}

#home_testimonial_area .home_testimonial_boxes .testimonial_single_container .testimonial_author .author_rating img {
    width: 100%;
}

#home_benifits_area {
    width: 70%;
    padding: 0% 15%;
}

#home_benifits_area hr {
    margin: 5% 0%;
    border: 0.5px solid #C7DBE5;
}

#home_benifits_area h1 {
    color: #013945;
    margin-bottom: 2%;
}

#home_benifits_area .home_page_benifts .benifit_row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.5%;
}

#home_benifits_area .home_page_benifts .benifit_row .benifit_card {
    width: 49%;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #ECE3FF;
}

#home_benifits_area .home_page_benifts .benifit_row .benifit_card .card_head {
    display: flex;
    padding: 2% 5%;
    color: #013945;
    font-size: 17px;
    align-items: center;
    text-align: left;
}

#home_benifits_area .home_page_benifts .benifit_row .benifit_card .card_head img {
    width: 7%;
    margin-right: 5%;
}

#home_benifits_area .home_page_benifts .benifit_row .benifit_card .card_body {
    display: flex;
    padding: 2% 5%;
    justify-content: flex-start;
    align-items: center;
}

#home_benifits_area .home_page_benifts .benifit_row .benifit_card .card_body .percent {
    font-size: 17px;
    margin-right: 5%;
}

#home_benifits_area .home_page_benifts .benifit_row .benifit_card .card_body .sentence {
    width: 80%;
    font-size: 15px;
    text-align: left;
}

#home_banner_area {
    background-color: #fdddd3;
    display: flex;
    justify-content: center;
}

#home_banner_area .home_banner_container {
    width: 78%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5% 0%;
}

#home_banner_area .home_banner_container .home_banner_content_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 50%;
}

#home_banner_area .home_banner_container .home_banner_content_area h1 {
    font-size: 45px;
    margin-block-start: 0%;
    margin-block-end: 0%;
}

#home_banner_area .home_banner_container .home_banner_content_area h1 strong {
    color: #e64a19;
    font-size: 55px;
}

#home_banner_area .home_banner_container .home_banner_content_area span {
    font-size: 16px;
    margin-bottom: 10px;
}

#home_banner_area .home_banner_container .home_banner_content_area button {
    font-size: 20px;
    background-color: #e64a19;
    color: #ffffff;
    border: none;
    padding: 2% 4%;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10%;
}

#home_banner_area .home_banner_container .home_banner_content_area button:hover {
    background-color: #ffffff;
    color: #e64a19;
}

#home_banner_area .home_banner_container .home_banner_img_area {
    /* background-color: #009A0E; */
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#home_banner_area .home_banner_container .home_banner_img_area img {
    width: 70%;
}

#home_download_area {
    width: 70%;
    padding: 0% 15% 3% 15%;
}

#home_download_area h1 {
    color: #013945;
    margin-bottom: 2%;
    margin-top: 3%;
}

#home_download_area .download_button_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#home_download_area .download_button_container a {
    width: 17%;
}

#home_download_area .download_button_container a img {
    width: 100%;
}

#home_quiz_area {
    width: 100%;
    height: 100%;
    background-color: #00000066;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home_quiz_area .quiz_container {
    width: 1200px;
    height: 600px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    overflow: hidden;
}

#home_quiz_area #quiz_question_9 {
    background: url('./quiz_result_bg.png');
    background-size: cover;
}

#home_quiz_area #quiz_question_9 .quiz_sidebar {
    background: transparent;
}

#home_quiz_area #quiz_question_9 .quiz_questionare {
    background: transparent;
}

#home_quiz_area .quiz_container .quiz_sidebar {
    width: 40%;
    background-color: #f4f4f4;
}

#home_quiz_area .quiz_container .quiz_sidebar .quiz_sidebar_heading {
    color: #013945;
    font-size: 22px;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    margin-left: 8%;
    margin-top: 5%;
}

#home_quiz_area .quiz_container .quiz_sidebar .quiz_sidebar_heading img {
    width: 50px;
    margin-right: 10px;
}

#home_quiz_area .quiz_container .quiz_sidebar p {
    margin-top: 5%;
    font-size: 20px;
    color: #013945;
    padding: 2% 8%;
    text-align: left;
    font-weight: 600;
}

#home_quiz_area .quiz_container .quiz_sidebar p strong {
    color: #e64a19;
}

#home_quiz_area .quiz_container .quiz_sidebar .codi_says {
    width: 100%;
    margin-top: 20%;
}

#home_quiz_area .quiz_container .quiz_sidebar .codi_say5 {
    width: 70%;
    margin-top: 5%;
}

#home_quiz_area .quiz_container .quiz_sidebar .result_heading {
    text-align: left;
    font-weight: 600;
    font-size: 27px;
    padding: 0% 8%;
}

#home_quiz_area .quiz_container .quiz_sidebar .result_continue {
    width: 84%;
    margin: 8%;
    color: #ffffff;
    background-color: #e64a19;
    padding: 3%;
    border: none;
    outline: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

#home_quiz_area .quiz_container .quiz_result #result_btn_small {
    display: none;
}

#home_quiz_area .quiz_container .quiz_sidebar .dopamine_info {
    color: #818181;
    font-size: 14px;
    margin-left: 20px;
    text-align: left;
    margin-top: 5%;
    width: 100%;
    display: block;
}

#home_quiz_area .quiz_container .quiz_sidebar .estimate_info {
    color: #818181;
    font-size: 14px;
    margin-left: 20px;
    text-align: left;
    width: 100%;
    display: block;
}

#home_quiz_area .quiz_container .quiz_sidebar .blockerx_perks_heading {
    width: 86%;
    padding: 10% 8% 0% 8%;
    text-align: left;
    color: #013945;
    font-size: 35px;
    margin-bottom: 3%;
}

#home_quiz_area .quiz_container .quiz_sidebar .blockerx_single_benifit {
    display: flex;
    width: 86%;
    padding: 0% 8%;
    align-items: center;
}

#home_quiz_area .quiz_container .quiz_sidebar .blockerx_single_benifit span {
    font-size: 15px;
    width: 30px;
    height: 30px;
    background-color: #e64a19;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}

#home_quiz_area .quiz_container .quiz_sidebar .blockerx_single_benifit p {
    width: 80%;
    font-size: 15px;
    margin-top: 1%;
}

#home_quiz_area .quiz_container .quiz_questionare {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_question_box {
    width: 70%;
    text-align: left;
    color: #013945;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_option_box {
    width: 70%;
    margin: 3% 0%;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_option_box .quiz_option_single {
    width: 90%;
    display: flex;
    align-items: center;
    border: 1px solid #BFBFBF;
    border-radius: 50px;
    padding: 3% 5%;
    margin-top: 2%;
    cursor: pointer;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_option_box .active_option {
    border: 1px solid #e64a19;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_option_box .quiz_option_single:hover {
    border: 1px solid #e64a19;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_option_box .quiz_option_single .quiz_option_single_num {
    width: 30px;
    height: 30px;
    background-color: #e64a19;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 50%;
    margin-right: 20px;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_option_box .quiz_option_single .quiz_option_single_tag {
    color: #414141;
    font-weight: 600;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_cta_btn {
    width: 70%;
}

#home_quiz_area .quiz_container .quiz_questionare .quiz_cta_btn button {
    width: 100%;
    padding: 3% 5%;
    cursor: pointer;
    background-color: #e64a19;
    color: #ffffff;
    border: 1px solid #e64a19;
    border-radius: 50px;
    margin-top: 3%;
}

#home_quiz_area .quiz_container .quiz_questionare .red_alert {
    width: 70%;
    text-align: left;
    font-size: 12px;
    color: #cf0e4e;
    font-style: italic;
}

#home_quiz_area .quiz_container .quiz_calculating {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#home_quiz_area .quiz_container .quiz_calculating h2 {
    font-size: 45px;
    color: #e64a19;
    margin-bottom: 2%;
}

#home_quiz_area .quiz_container .quiz_calculating p {
    font-size: 20px;
}

#home_quiz_area .quiz_container .quiz_result {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

#home_quiz_area .quiz_container .quiz_result .quiz_result_inner {
    width: 60%;
    height: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

#home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_avg {
    width: 45%;
    color: #ffffff;
    background: linear-gradient(180deg, #D34214 0%, rgba(211, 66, 20, 0.92) 22.4%, rgba(211, 66, 20, 0.81) 44.79%, rgba(211, 66, 20, 0.405) 85.25%, rgba(211, 66, 20, 0) 100%);
}

#home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_avg h1 {
    font-size: 30px;
    margin-top: 4%;
}

#home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_avg span {
    font-size: 20px;
}

#home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_user {
    width: 45%;
    color: #ffffff;
    background: linear-gradient(180deg, #D34214 0%, rgba(211, 66, 20, 0.92) 22.4%, rgba(211, 66, 20, 0.81) 44.79%, rgba(211, 66, 20, 0.405) 85.25%, rgba(211, 66, 20, 0) 100%);
}

#home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_user h1 {
    font-size: 30px;
    margin-top: 4%;
}

#home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_user span {
    font-size: 20px;
}

#home_quiz_area .quiz_container .quiz_premium_end {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#home_quiz_area .quiz_container .quiz_premium_end .codi_area {
    width: 70%;
}

#home_quiz_area .quiz_container .quiz_premium_end .codi_area img {
    width: 12%;
}

#home_quiz_area .quiz_container .quiz_premium_end h1 {
    width: 70%;
    font-size: 30px;
    color: #e64a19;
    font-weight: 600;
    line-height: 50px;
}

#home_quiz_area .quiz_container .quiz_premium_end .user_review {
    width: 70%;
    /* background-color: #414141; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
}

#home_quiz_area .quiz_container .quiz_premium_end .user_review img {
    width: 30%;
}

#home_quiz_area .quiz_container .quiz_premium_end .user_review p {
    width: 60%;
    margin-top: 2%;
    font-size: 14px;
}

#home_quiz_area .quiz_container .quiz_premium_end .user_review span {
    width: 60%;
    font-size: 12px;
    text-align: right;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card {
    background-color: #FFF2EE;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 7%;
    border-radius: 10px;
    padding-bottom: 2%;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .premium_offer_area {
    width: 100px;
    font-size: 14px;
    background-color: #e64a19;
    height: 30px;
    padding: 3px 7px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    color: #ffffff;
    position: relative;
    top: -15px;
    right: 30px;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content {
    width: 92%;
    display: flex;
    justify-content: space-between;
    padding: 0% 4%;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area h3 {
    font-size: 25px;
    text-align: left;
    color: #013945;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area p {
    font-size: 12px;
    text-align: left;
    color: #646464;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area p strike {
    color: #e64a19;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area p strong {
    color: #013945;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area span {
    color: #013945;
    font-size: 13px;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_price_area {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_price_area h2 {
    font-size: 27px;
    color: #013945;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_price_area h4 {
    font-size: 19px;
    color: #e64a19;
}

#home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_price_area p {
    font-size: 12px;
    color: #646464;
}

#home_quiz_area .quiz_container .quiz_premium_end button {
    width: 70%;
    text-align: center;
    padding: 2% 0%;
    font-size: 17px;
    color: #ffffff;
    background-color: #e64a19;
    border: none;
    border-radius: 50px;
    margin-top: 3%;
    cursor: pointer;
}

#main_quiz_icon {
    background: transparent;
    border: none;
    width: 200px;
    z-index: 100;
    position: fixed;
    bottom: 70px;
    right: 100px;
}

#main_quiz_icon img {
    width: 100%;
    cursor: pointer;
}

#main_quiz_close {
    background: #ffffff;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 105;
    position: fixed;
    border: 1px solid #e64a19;
    color: #e64a19;
    bottom: 70px;
    right: 100px;
    font-size: 45px;
    cursor: pointer;
}

#home_zenze_promotion {
    width: 100%;
    background-color: #636363;
    cursor: pointer;
    height: 200px;
    /* background: url('./zenze_bg_2.jpg'); */
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    /* padding: 2%; */
}

#home_zenze_promotion .zenze_filter {
    backdrop-filter: blur(0px);
    width: 96%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 2%;
}

#home_zenze_promotion .zenze_filter .zenze_prom_left {
    width: 40%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #39393966;
    padding: 3%;
    border-radius: 10px;
}

#home_zenze_promotion .zenze_filter .zenze_prom_left h1 {
    text-align: left;
}

#home_zenze_promotion .zenze_filter .zenze_prom_left a {
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
}

#home_zenze_promotion .zenze_filter .zenze_prom_right {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 10%;
    align-items: flex-end;
    overflow: hidden;
}

#home_zenze_promotion .zenze_filter .zenze_prom_right img {
    width: 50%;
    height: 90%;
    object-fit: cover;
}

@media (max-width: 1600px) {
    #home_quiz_area .quiz_container {
        width: 1000px;
        height: 520px;
    }

    #home_quiz_area .quiz_container .quiz_sidebar .quiz_sidebar_heading {
        font-size: 20px;
    }

    #home_quiz_area .quiz_container .quiz_sidebar p {
        font-size: 18px;
    }

    #home_quiz_area .quiz_container .quiz_sidebar .result_heading {
        font-size: 25px;
    }

    #home_quiz_area .quiz_container .quiz_sidebar .result_continue {
        font-size: 14px;
    }

    #home_quiz_area .quiz_container .quiz_sidebar .dopamine_info {
        font-size: 12px;
    }

    #home_quiz_area .quiz_container .quiz_sidebar .estimate_info {
        font-size: 12px;
    }

    #home_quiz_area .quiz_container .quiz_sidebar .blockerx_perks_heading {
        font-size: 33px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end h1 {
        font-size: 28px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .user_review p {
        font-size: 12px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .user_review span {
        font-size: 10px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card .premium_offer_area {
        width: 90px;
        font-size: 12px;
        height: 28px;
        top: -14px;
        right: 28px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area h3 {
        font-size: 23px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area p {
        font-size: 10.5px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_plan_area span {
        font-size: 12px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_price_area h2 {
        font-size: 25px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_price_area h4 {
        font-size: 17px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card .card_content .card_price_area p {
        font-size: 12px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end button {
        font-size: 15px;
    }

    #main_quiz_icon {
        width: 160px;
        bottom: 70px;
        right: 100px;
    }

    #main_quiz_icon img {
        width: 100%;
        cursor: pointer;
    }

    #main_quiz_close {
        width: 70px;
        height: 70px;
        bottom: 50px;
        right: 70px;
        font-size: 35px;
    }
}

@media (max-width: 1100px) {
    #hero_area .pricing_containers .pricing_card {
        width: 180px;
    }

    #home_banner_area .home_banner_container .home_banner_content_area {
        width: 50%;
    }

    #home_zenze_promotion .zenze_filter .zenze_prom_left {
        margin-left: 5%;
    }

    #home_zenze_promotion .zenze_filter .zenze_prom_right {
        margin-right: 5%;
    }
}

@media (max-width: 900px) {
    #hero_area {
        flex-direction: column-reverse;
    }

    #hero_area .left_containers {
        margin-top: 20px;
    }

    #home_benifits_area {
        width: 90%;
        padding: 0% 5%;
    }

    #home_download_area {
        width: 90%;
        padding: 0% 5% 3% 5%;
    }

    #home_banner_area .home_banner_container {
        width: 90%;
    }
}

@media (max-width: 700px) {
    #hero_area {
        width: 90%;
        padding: 4% 5% 4% 5%;
    }

    #hero_area .left_containers {
        width: 80%;
    }

    #home_app_stats_area {
        padding: 3% 5% 0% 5%;
        width: 90%;
    }

    #home_testimonial_area {
        padding: 3% 5% 0% 5%;
        width: 90%;
    }

    #home_banner_area .home_banner_container {
        flex-direction: column;
    }

    #home_banner_area .home_banner_container .home_banner_content_area {
        width: 90%;
    }

    #home_banner_area .home_banner_container .home_banner_img_area {
        width: 90%;
        justify-content: center;
        margin-top: 2%;
    }

    #home_banner_area .home_banner_container .home_banner_img_area img {
        width: 100%;
    }

    #home_zenze_promotion {
        height: auto;
    }

    #home_zenze_promotion .zenze_filter {
        flex-direction: column;
        padding: 2% 2% 0% 2%;
    }

    #home_zenze_promotion .zenze_filter .zenze_prom_left {
        width: 96%;
        margin-left: 0%;
    }

    #home_zenze_promotion .zenze_filter .zenze_prom_right {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        margin-right: 0%;
        align-items: flex-end;
        margin-top: 2%;
    }
    
    #home_zenze_promotion .zenze_filter .zenze_prom_right img {
        width: 60%;
        height: auto;
        object-fit: cover;
    }
}

@media (max-width: 600px) {
    #home_app_stats_area .home_app_stats_boxes {
        flex-direction: column;
    }

    #home_app_stats_area .home_app_stats_boxes .home_app_stats_container {
        width: 80%;
        /* background-color: #84F890; */
        margin-bottom: 2%;
    }

    #home_testimonial_area .home_testimonial_boxes {
        flex-direction: column;
        align-items: center;
    }

    #home_testimonial_area .home_testimonial_boxes .testimonial_single_container {
        width: 90%;
        padding: 4%;
        margin-bottom: 2%;
    }

    #home_testimonial_area .home_testimonial_boxes .testimonial_single_container .testimonial_author .author_rating {
        width: 20%;
    }

    #home_benifits_area .home_page_benifts .benifit_row {
        flex-direction: column;
        margin-bottom: 0%;
    }

    #home_benifits_area .home_page_benifts .benifit_row .benifit_card {
        width: 100%;
        margin-bottom: 2%;
    }

    #home_quiz_area .quiz_container {
        width: 95%;
        height: 80%;
    }

    #home_quiz_area .quiz_container .quiz_sidebar {
        display: none;
    }

    #home_quiz_area .quiz_container .quiz_questionare {
        width: 100%;
        height: 100%;
    }

    #home_quiz_area .quiz_container #sidebar_result {
        display: block;
        width: 100%;
    }

    #home_quiz_area .quiz_container {
        flex-direction: column;
    }

    #home_quiz_area .quiz_container #sidebar_result #sidebar_head {
        display: none;
    }

    #home_quiz_area .quiz_container #sidebar_result #sidebar_message {
        display: none;
    }

    #home_quiz_area .quiz_container #sidebar_result #result_btn_large {
        display: none;
    }

    #home_quiz_area .quiz_container .quiz_result {
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #home_quiz_area .quiz_container .quiz_result .quiz_result_inner {
        width: 80%;
        height: 95%;
    }

    #home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_avg h1 {
        font-size: 20px;
    }

    #home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_avg span {
        font-size: 15px;
    }

    #home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_user h1 {
        font-size: 20px;
    }

    #home_quiz_area .quiz_container .quiz_result .quiz_result_inner .quiz_result_user span {
        font-size: 15px;
    }

    #home_quiz_area .quiz_container .quiz_result #result_btn_small {
        display: block;
        width: 84%;
        margin: 8%;
        color: #ffffff;
        background-color: #e64a19;
        padding: 3%;
        border: none;
        outline: none;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
    }

    #main_quiz_icon {
        width: 100px;
        bottom: 20px;
        right: 30px;
    }

    #main_quiz_close {
        width: 60px;
        height: 60px;
        bottom: 20px;
        right: 30px;
        font-size: 30px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end {
        width: 100%;
        height: 100%;
    }

    #home_quiz_area .quiz_container .quiz_premium_end h1 {
        width: 90%;
        font-size: 25px;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .user_review p {
        width: 75%;
    }

    #home_quiz_area .quiz_container .quiz_premium_end .premium_card {
        width: 90%;
    }
}

@media (max-width: 480px) {
    #hero_area {
        width: 100%;
        padding: 50px 0px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    #hero_area .left_containers {
        width: 80%;
        padding: 4%;
    }

    #hero_area .pricing_containers {
        flex-direction: column;
        width: 100%;
        margin: 0px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    #hero_area .left_containers .hero_list_item span {
        margin-right: 10px;
    }

    #hero_area .pricing_containers .pricing_card .pricing_card_main .pricing_card_main_body span {
        margin-bottom: 30px;
    }

    #home_app_stats_area {
        padding: 40px 5% 15px 5%;
    }

    #home_app_stats_area .home_stats_content_area {
        margin-bottom: 15px
    }

    #home_banner_area .home_banner_container {
        width: 100%;
    }

    #home_banner_area .home_banner_container .home_banner_content_area button {
        padding: 2% 8%;
        margin: 5% 0% 9% 0%;
        width: 60%;
    }

    #home_banner_area .home_banner_container .home_banner_img_area img {
        margin-bottom: 20px;
    }

    #home_testimonial_area {
        padding: 40px 5% 20px 5%;
    }

    #home_testimonial_area .home_testimonial_boxes .testimonial_single_container {
        margin-bottom: 15px;
    }

    #home_benifits_area h1 {
        margin-bottom: 25px;
    }

    #home_testimonial_area hr {
        margin: 30px 0px 15px 0px;
        border: 0.5px solid #C7DBE5;
    }

    #home_benifits_area .home_page_benifts .benifit_row .benifit_card {
        width: 100%;
        margin-bottom: 15px;
    }

    #home_benifits_area .home_page_benifts .benifit_row .benifit_card .card_head img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        flex: 0 0 30px;
        flex-shrink: 0;
    }

    #home_banner_area .home_banner_container .home_banner_content_area h1 strong {
        font-size: 2.5rem;
    }

    #home_banner_area {
        padding: 20px 0px;
    }

    #home_banner_area .home_banner_container .home_banner_content_area h1 {
        font-size: 2rem;
        color: #013945;
    }

    #hero_area .pricing_containers .lifetime {
        margin-right: 0px;
        margin-bottom: 15px;
    }

    #hero_area .pricing_containers .pricing_card {
        width: 85%;
        margin-bottom: 20px;
    }

    .pricing_card_main {
        height: 240px;
    }

    #home_app_stats_area .home_app_stats_boxes .home_app_stats_container {
        width: 100%;
    }

    #home_download_area .download_button_container a {
        width: 50%;
    }
}

@media (max-width: 400px) {
    .pricing_card_main {
        height: 260px;
    }

    #hero_area .left_containers {
        width: 76%;
        padding: 6%;
    }

    #home_banner_area .home_banner_container .home_banner_content_area h1 strong {
        font-size: 2rem;
    }

    #home_banner_area .home_banner_container .home_banner_content_area h1 {
        font-size: 1.8rem;
    }
}